import React, { useEffect, useState } from "react";
import FormControl from "react-bootstrap/FormControl";
import ImageGallery from "react-image-gallery";
import Table from "react-bootstrap/Table";
import { Typography } from "@mui/material";

import LaboratoryTableElement from "../../components/laboratory-components/laboratory-table-element";
import TableSearchLogic from "../../components/laboratory-components/laboratory-table-search.js";

import LaboratoryNZOKData from "../../data/lab-nzko-data";
import LaboratoryNoNZOKData from "../../data/lab-no-nzko-data";

import e1 from "./../../images/Laboratory/Еquipment/e1.jpg";
import e2 from "./../../images/Laboratory/Еquipment/e2.jpg";
import e3 from "./../../images/Laboratory/Еquipment/e3.jpg";
import e4 from "./../../images/Laboratory/Еquipment/e4.jpg";
import e5 from "./../../images/Laboratory/Еquipment/e5.png";
import DiamedM1 from "./../../images/Laboratory/DiamedM1.PNG";

import "./laboratory.css";
import "react-image-gallery/styles/css/image-gallery.css";

const Laboratory = (props) => {
  const [search, setSearch] = useState("");
  const [searchNoNzok, setSearchNoNzok] = useState("");

  const [data, setData] = useState(LaboratoryNZOKData);
  const [dataNoNzok, setDataNoNzok] = useState(LaboratoryNoNZOKData);

  useEffect(() => {
    if (search.length > 0) {
      TableSearchLogic(LaboratoryNZOKData, search, setData);
    } else {
      setData(LaboratoryNZOKData);
    }
  }, [search]);

  useEffect(() => {
    if (searchNoNzok.length > 0) {
      TableSearchLogic(LaboratoryNoNZOKData, searchNoNzok, setDataNoNzok);
    } else {
      setDataNoNzok(LaboratoryNoNZOKData);
    }
  }, [searchNoNzok]);

  const nzokData = data.map((el, index) => {
    const keyValue = "single_table_el_" + index;
    return <LaboratoryTableElement el={el} key={keyValue}></LaboratoryTableElement>;
  });

  const noNzokData = dataNoNzok.map((el, index) => {
    const keyValue = "single_table_el_nonzok" + index;
    return <LaboratoryTableElement el={el} key={keyValue}></LaboratoryTableElement>;
  });

  const Title = ({ text }) => {
    return (
      <>
        <Typography
          className="information"
          key="information"
          style={{ color: "gray", fontSize: "19.5pt", marginTop: "25px" }}
        >
          {" "}
          <b>{text}</b>
        </Typography>
        <hr style={{ marginBottom: "25px" }}></hr>
      </>
    );
  };

  return (
    <div className="main-container">
      <img alt="pic" src={DiamedM1} className="diamed-m-photo"></img>
      <div className="col-fixed-big-laboratory">
        <Title text="Изследвания по договор с НЗОК"></Title>
        <div className="search-bar-Laboratory-NZOK" key="search-bar">
          <FormControl
            type="text"
            placeholder="Търси изследванe"
            style={{ height: "50px", fontSize: "1.5rem" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <br></br>
        <Table striped bordered hover size="sm">
          <tbody>
            {nzokData.length > 0 ? (
              nzokData
            ) : (
              <tr>
                <td>
                  <b>Няма открити изследвания.</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="results-info_container">
          <Title text="Получаване на резултати"></Title>
          <div className="results-info_types-container">
            <ul>
              <li>Viber</li>
              <li>Електронна поща</li>
            </ul>
            <ul>
              <li>ID №</li>
              <li>На място</li>
              <li>НЗИС до лекаря назначил изследванията</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-fixed-laboratory">
        <Title text="Платени изследвания"></Title>
        <div className="search-bar-Laboratory-NZOK" key="search-bar">
          <FormControl
            type="text"
            placeholder="Търси платено изследван"
            style={{ height: "50px", fontSize: "1.5rem" }}
            onChange={(e) => setSearchNoNzok(e.target.value)}
          />
        </div>
        <br></br>
        <Table striped bordered hover size="sm">
          <tbody>
            {noNzokData.length > 0 ? (
              noNzokData
            ) : (
              <tr>
                <td>
                  <b>Няма открити изследвания.</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="galery">
          <Title text="Апаратура"></Title>
          <ImageGallery
            showFullscreenButton={false}
            showPlayButton={false}
            items={[
              { original: e5, thumbnail: e5, showFullscreenButton: false },
              { original: e1, thumbnail: e1, showFullscreenButton: false },
              { original: e2, thumbnail: e2 },
              { original: e3, thumbnail: e3 },
              { original: e4, thumbnail: e4 },
            ]}
          />
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Laboratory;
